<template>
  <el-dialog title="调整预算"
             :close-on-click-modal='false'
             append-to-body
             :visible.sync="dialogVisible"
             width="50%">
    <table-page dataName="logs"
                ref="table"
                init
                :params="{...searchParams}"
                :request="balanceLog">
      <el-table-column show-overflow-tooltip
                       label="目标金额">
        <template slot-scope="scope">
          {{scope.row.dest_recharge/100}}
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="修改金额">
        <template slot-scope="scope">
          <span :style="{color:scope.row.balance>0?'#f44336':'#4caf50'}">
            {{scope.row.balance>0?'+':''}}{{scope.row.balance/100}}
          </span>

        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="原始金额">
        <template slot-scope="scope">
          {{scope.row.source_recharge/100}}
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="修改人">
        <template slot-scope="scope">
          {{scope.row.name}}
        </template>
      </el-table-column>
      <el-table-column prop="reason"
                       show-overflow-tooltip
                       label="修改原因">
      </el-table-column>
      <el-table-column prop="altered_at"
                       show-overflow-tooltip
                       label="修改时间">
      </el-table-column>
    </table-page>
    <span slot="footer"
          class="dialog-footer">
    </span>
  </el-dialog>
</template>

<script>
import TablePage from '@/components/TablePage'
import { balanceLog } from '@/api/market'
export default {
  data () {
    return {
      balanceLog,
      searchParams: null,
      dialogVisible: false
    }
  },
  components: {
    TablePage
  },
  created () {

  },
  mounted () {
  },
  methods: {
    showDialog (scope) {
      this.dialogVisible = true
      this.searchParams = scope
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    }

  }
}
</script>

<style scoped lang="scss">
</style>
