<template>
  <el-dialog title="扣除项修改"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible"
             width="50%">
    <el-form :model="form"
             :rules="rules"
             ref="form"
             label-position="top">

      <el-form-item label="当前金额"
                    prop="province">
        {{value}}元
      </el-form-item>
      <el-form-item label="增减金额"
                    prop="deduct">
        <div class="flex">
          <el-input type="text"
                    class="mr-15 width300"
                    placeholder="单行输入"
                    v-model="form.deduct"></el-input>
          <span>元</span>
        </div>
      </el-form-item>
      <el-form-item label="修改人"
                    prop="name">
        <div class="flex">
          <el-input type="text"
                    class="mr-15 width300"
                    placeholder="单行输入"
                    v-model.number="form.name"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="修改原因"
                    prop="reason">
        <el-input type="textarea"
                  :rows="4"
                  placeholder="多行输入"
                  v-model="form.reason"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"
                 @click="submitForm('form')">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { alterDeduct } from '@/api/market'
export default {
  props: {
    area: Object
  },
  data () {
    var validatPrices = (rule, value, callback) => {
      if (rule.required === false && (value === '' || value === null || value === undefined)) {
        callback()
      }
      const isPrice = /(^[-]?[0-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/
      if (isPrice.test(value)) {
        callback()
      } else {
        callback(new Error(rule.message || '请输入正确的金额，例：10, 10.00'))
      }
    }
    var validatName = (rule, value, callback) => {
      if (rule.required === false && (value === '' || value === null || value === undefined)) {
        callback()
      }
      const nameReg = /^[\u4E00-\u9FA5]{2,4}$/
      if (nameReg.test(value)) {
        callback()
      } else {
        callback(new Error(rule.message || '请输入正确姓名'))
      }
    }
    return {
      value: '',
      dialogVisible: false,
      form: {
        deduct: '',
        name: '',
        reason: '',
        city: '',
        district: '',
        net: '',
        province: ''
      },
      rules: {
        deduct: [
          { required: true, message: '请输入金额', trigger: 'blur' },
          { validator: validatPrices, trigger: 'blur' }
        ],
        reason: [
          { required: true, message: '请输入原因', trigger: 'blur' }
        ],
        name: [
          { validator: validatName, trigger: 'blur' },
          { required: true, message: '请输入修改人', trigger: 'blur' }
        ]
      }
    }
  },
  components: {
  },
  created () {

  },
  mounted () {
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const formData = JSON.parse(JSON.stringify(this.form))
          formData.deduct = parseFloat(formData.deduct)
          formData.deduct *= 100
          const res = await alterDeduct(formData)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success'
            })
            this.$emit('on-success')
            this.dialogVisible = false
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          return false
        }
      })
    },
    showDialog (money, params) {
      this.value = money / 100
      this.form.province = params.province
      this.form.city = params.city
      this.form.district = params.district
      this.form.net = params.net
      this.form.name = ''
      this.form.deduct = ''
      this.form.reason = ''
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
