<template>
  <el-dialog title="调整预算"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible"
             width="50%">
    <el-form :model="form"
             v-loading="loading"
             :rules="rules"
             ref="form"
             v-if="activity"
             label-position="top">

      <el-form-item label="活动名称"
                    prop="province">
        {{  activity.name || ''}}
      </el-form-item>
      <el-form-item label="活动类型"
                    prop="title">
        {{activity.type | typeName}}
      </el-form-item>
      <el-form-item label="批次号"
                    prop="code">
        <span v-for="(item,index) in activity.stock_ids"
              :key="index">
          {{item}}
        </span>
      </el-form-item>
      <el-form-item label="当前预算"
                    prop="title">
        {{activity.recharge_money/100}}元 <el-button type="primary"
                   size="small"
                   class="ml-15"
                   @click="refreshBudget">刷新</el-button>
      </el-form-item>
      <template v-if="updateRechargeMoney">
        <el-form-item label="预算调整"
                      prop="code">
          <span :style="{color:updateRechargeMoney/100>0?'green':'red'}">{{updateRechargeMoney/100}}元</span>
        </el-form-item>
        <el-alert v-if="updateRechargeMoney<0"
                  :title="`减少的活动预算${Math.abs(updateRechargeMoney/100)}元将加入余额`"
                  type="warning"
                  :closable="false">
        </el-alert>
        <el-form-item label="预算来源"
                      v-if="updateRechargeMoney>0"
                      prop="budget_source">
          <el-select v-model="form.budget_source"
                     class="width300"
                     placeholder="请选择">
            <el-option v-for="item in typeList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="赋能单号"
                      v-if="updateRechargeMoney>0 && form.budget_source === 'ENERGIZE'"
                      required>
          <div class="flex">
            <el-input type="text"
                      class="mr-15 width300"
                      placeholder="请输入赋能单号"
                      v-model="energizeNo"></el-input>
            <el-button type="primary"
                       @click="addEnergizeNo">添加</el-button>
          </div>
          <div>
            <el-tag type="success"
                    closable
                    @close="delEnergizeNo(index)"
                    v-for="(item,index) in form.energize_nos"
                    :key="index">{{item}}</el-tag>
          </div>
        </el-form-item>
      </template>

    </el-form>

    <span slot="footer"
          v-if="updateRechargeMoney"
          class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"
                 :loading="btnLoading"
                 @click="submitForm('form')">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { fetchSinopec, updateBudget, refreshBudget } from '@/api/market'
export default {
  data () {
    var validatPrices = (rule, value, callback) => {
      if (rule.required === false && (value === '' || value === null || value === undefined)) {
        callback()
      }
      const isPrice = /(^[-]?[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/
      if (isPrice.test(value)) {
        callback()
      } else {
        callback(new Error(rule.message || '请输入正确的金额，例：10, 10.00'))
      }
    }
    return {
      btnLoading: false,
      loading: false,
      updateRechargeMoney: '',
      activity: null,
      energizeNo: '',
      typeList: [{
        value: 'ENERGIZE',
        label: '赋能下单'
      }, {
        value: 'BALANCE',
        label: '使用余额'
      }],
      value: '',
      areaAddress: {},
      dialogVisible: false,
      form: {
        id: '',
        budget_source: '',
        energize_nos: [],
        energize_money: ''
      },
      rules: {
        energize_money: [
          { required: true, message: '请填写赋能下单金额', trigger: 'blur' },
          { validator: validatPrices, trigger: 'blur' }
        ],
        budget_source: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ]
      }
    }
  },
  components: {
  },

  filters: {
    typeName (param) {
      switch (param) {
        case 'RANDOM':
          return '随机立减'
        case 'RATION':
          return '定额立减'
        case 'PUBLIC':
          return '公开派券'
        case 'MARKETER':
          return '员工派券'
        default:
          break
      }
    },
    budgetName (param) {
      switch (param) {
        case 'ENERGIZE':
          return '赋能下单'
        case 'BALANCE':
          return '余额下单'
        default:
          break
      }
    }
  },
  created () {

  },
  mounted () {
  },
  methods: {
    delEnergizeNo (index) {
      this.form.energize_nos.splice(index, 1)
    },
    async refreshBudget () {
      this.loading = true
      const res = await refreshBudget({
        id: this.activity.id
      })
      this.loading = false
      if (res.meta.code === 0) {
        this.updateRechargeMoney = res.data.update_recharge_money
        if (this.updateRechargeMoney < 0) {
          this.form.budget_source = 'BALANCE'
        }
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    addEnergizeNo () {
      if (this.energizeNo !== '') {
        const flag = this.activity.energize_nos.find((item) => { return this.energizeNo === item })
        if (flag) {
          this.$message.error('请勿重复添加')
        } else {
          this.form.energize_nos.push(this.energizeNo)
          this.energizeNo = ''
        }
      } else {
        this.$message.error('请输入赋能单号')
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const formData = JSON.parse(JSON.stringify(this.form))
          formData.energize_money = parseFloat(formData.energize_money).toFixed(2)
          this.btnLoading = true
          const res = await updateBudget(this.form)
          this.btnLoading = false
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: '调整成功',
              type: 'success'
            })
            this.$emit('on-success')
            this.dialogVisible = false
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          return false
        }
      })
    },
    showDialog (scope) {
      this.dialogVisible = true
      this.activity = scope
      this.energizeNo = ''
      this.updateRechargeMoney = ''
      const formData = JSON.parse(JSON.stringify(scope))

      this.form = {
        id: formData.id,
        budget_source: '',
        energize_nos: [],
        energize_money: ''
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    async fetchSinopec () {
      const res = await fetchSinopec()
      if (res.meta.code === 0) {
        this.selectList = res.data.refuels
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
