<template>
  <el-dialog title="辖内所有活动资金情况"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible"
             width="50%">
    <el-alert :title="`当前查看区域：${areaTxt}`"
              type="success"
              :closable="false" />
    <div class="sum-wrap"
         v-loading="loading">
      <div class="item">
        <div class="title">
          总预算
        </div>
        <div class="num">
          {{budget?budget.total_budget:0 | toThousandFilter}}
        </div>
      </div>
      <div class="item">
        <div class="title">
          进行中活动占用预算
        </div>
        <div class="num">
          {{budget?budget.run_budget:0| toThousandFilter}}
        </div>
      </div>
      <div class="item">
        <div class="title">
          结束活动消耗金额
        </div>
        <div class="num">
          {{budget?budget.end_budget:0| toThousandFilter}}
        </div>
      </div>
      <div class="item">
        <div class="title">
          可使用余额
        </div>
        <div class="num">
          {{budget?budget.balance_budget:0| toThousandFilter}}
        </div>
      </div>
      <div class="item">
        <div class="title">
          其他扣除项
        </div>
        <div class="num">
          {{budget?budget.deduct:0 | toThousandFilter}}
        </div>
      </div>
    </div>

    <span slot="footer"
          class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"
                 @click="dialogVisible = false">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { queryBudget } from '@/api/market'
import { toThousandFilter } from '@/utils'
export default {
  data () {
    return {
      dialogVisible: false,
      loading: false,
      budget: null,
      searchParams: null
    }
  },
  components: {
  },
  computed: {
    areaTxt () {
      if (this.searchParams) {
        return this.searchParams.province ? this.searchParams.province + this.searchParams.city + this.searchParams.district : '全部'
      } else {
        return '全部'
      }
    }
  },
  filters: {
    toThousandFilter
  },
  created () {

  },
  mounted () {
  },
  methods: {
    async queryBudget (params) {
      this.loading = true
      const res = await queryBudget({ ...params, is_current: false })
      this.loading = false
      if (res.meta.code === 0) {
        this.budget = res.data
      } else {
        this.$message.error(res.meta.msg)
      }
    },

    showDialog (params) {
      this.dialogVisible = true
      this.searchParams = params
      this.$nextTick(() => {
        this.queryBudget(params)
      })
    }

  }
}
</script>

<style scoped lang="scss">
.sum-wrap {
  .item {
    margin-bottom: 15px;
    .title {
      font-size: 12px;
      margin: 15px 0px;
    }
    .num {
      font-size: 32px;
      font-weight: bold;
    }
  }
}
</style>
