<template>
  <div class="container">
    <CurrentSum ref="currentSum" />
    <div class="description">
      <div class="title">当前查看层级：{{areaTxt}}</div>
      <div class="desc-content">
        <span>当前层级资金使用情况（不包含下级区域预算）</span>
        <a @click="currentSum"
           class="fr">查看辖内所有活动资金情况</a>
      </div>
    </div>
    <el-row class="sum-wrap">
      <el-col :span="8"
              class="sum-item">
        <div class="title">总预算</div>
        <div class="sum">
          {{budget?budget.total_budget:0 | toThousandFilter}}
        </div>
        <div v-if="searchParams && searchParams.province && role==='ADMIN'">
          <el-button type="text"
                     @click="$refs.adjustSumBudet.showDialog(budget?budget.total_budget:0,searchParams)">修改</el-button>
          <el-button type="text"
                     @click="$refs.adjustLog.showDialog(searchParams)">历史记录</el-button>
        </div>
      </el-col>
      <el-col :span="8"
              class="sum-item">
        <div class="title">进行中活动占用预算</div>
        <div class="sum">
          {{budget?budget.run_budget:0| toThousandFilter}}
        </div>
      </el-col>
      <el-col :span="8"
              class="sum-item">
        <div class="title">结束活动消耗金额</div>
        <div class="sum">
          {{budget?budget.end_budget:0| toThousandFilter}}
        </div>
      </el-col>

    </el-row>
    <el-row class="sum-wrap">
      <el-col :span="8"
              class="sum-item">
        <div class="title">领取核销率</div>
        <div class="sum">
          {{verify_probability+'%'}}
        </div>
      </el-col>
      <el-col :span="8"
              class="sum-item">
        <div class="title">可使用余额</div>
        <div class="sum">
          {{budget?budget.balance_budget:0| toThousandFilter}}
        </div>
      </el-col>
      <el-col :span="8"
              class="sum-item">
        <div class="title">其他扣除项
        </div>
        <div class="sum">
          {{budget?budget.deduct:0 | toThousandFilter}}
        </div>
        <div v-if="searchParams && searchParams.province && role==='ADMIN'">
          <el-button type="text"
                     @click="$refs.adjustDeduct.showDialog(budget?budget.deduct:0,searchParams)">修改</el-button>
          <el-button type="text"
                     class="mr-15"
                     @click="$refs.changeLog.showDialog(searchParams)">历史记录</el-button>
        </div>
      </el-col>
    </el-row>
    <table-page dataName="activities"
                :tabList="tabList"
                :search="search"
                :params="{is_current:isCurrent}"
                :searchFun="searchFun"
                @on-success="fetchSuccess"
                @on-search="onSearch"
                ref="table"
                :request="querySearh">
      <template slot="button">
        <el-button type="primary"
                   class="mr-15"
                   v-permission="['ADMIN']"
                   @click="$refs.setActivity.showDialog()">新建活动</el-button>
        <FileExport :params="exportParam"
                    v-if="exportParam">导出活动记录</FileExport>
      </template>
      <template slot="tab-slot">
        <el-radio-group v-model="isCurrent"
                        @change="typeChange">
          <el-radio-button :label="item.value"
                           v-for="item in typeList"
                           :key="item.value">{{item.label}}</el-radio-button>
        </el-radio-group>
      </template>
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="left"
                   inline
                   label-width="140px"
                   class="demo-table-expand">

            <el-form-item label="仅邮储卡可享受">
              <span>{{ props.row.is_postal?'是':'否' }}</span>
            </el-form-item>
            <el-form-item label="赋能单号">
              <div v-for="(item,index) in props.row.energize_nos"
                   :key="index">
                {{item}}
              </div>
            </el-form-item>
            <el-form-item label="批次号">
              <div v-for="(item,index) in props.row.stock_data"
                   :key="index">
                <span class="mr-15">{{item.stock_id}}（{{item.name}}）</span>
                <FileExport :params="props.row.merchant_export[item.stock_id]"
                            type="text"
                            v-if="props.row.merchant_export[item.stock_id]">导出可核销商户号</FileExport>
              </div>
            </el-form-item>
            <el-form-item label="总预算">
              <span>{{ props.row.recharge_money/100+'元' }}</span>
            </el-form-item>
            <el-form-item label="最终领取数量">
              <span>{{ props.row.status === 'NORMAL' ? props.row.receive_total : '请在活动结束次日10:00后查询' }}</span>
            </el-form-item>
            <el-form-item label="最终核销数量">
              <span>{{ props.row.status === 'NORMAL' ? props.row.charge_order_total : '请在活动结束次日10:00后查询' }}</span>
            </el-form-item>
            <el-form-item label="最终核销金额">
              <span>{{ props.row.status === 'NORMAL' ? props.row.charge_consume_money/100+'元' : '请在活动结束次日10:00后查询' }}</span>
            </el-form-item>
            <el-form-item label="消耗剩余金额">
              <span>{{ props.row.status === 'NORMAL' ? (props.row.recharge_money-props.row.charge_consume_money)/100+'元' : '请在活动结束次日10:00后查询' }}</span>
            </el-form-item>
            <el-form-item label="附件"
                          v-if="props.row.file_data">
              <a :href="props.row.file_data.url">{{props.row.file_data.source_name}}</a>
            </el-form-item>
            <el-form-item label="备注">
              <span>{{ props.row.desc }}</span>
            </el-form-item>
            <el-form-item label="负责人">
              <span>{{ props.row.creator }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="id"
                       show-overflow-tooltip
                       label="活动ID">
      </el-table-column>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="活动名称">
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="活动类型">
        <template slot-scope="scope">
          <span>{{scope.row.type | typeName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="活动区域"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{`${scope.row.province || ''}${scope.row.city || ''}${scope.row.district || ''}${scope.row.net || ''}`}}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="起止时间">
        <template slot-scope="scope">
          {{scope.row.started_at}}-{{scope.row.ended_at}}
        </template>
      </el-table-column>
      <el-table-column label="活动状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 'SUBMITED'">
            <el-badge is-dot
                      type="warning"
                      class="mr-15"></el-badge>未开始
          </span>
          <span v-else-if="scope.row.status === 'PENDING'">
            <el-badge is-dot
                      type="success"
                      class="mr-15"></el-badge>正在进行
          </span>
          <span v-else-if="scope.row.status === 'WAITING'">
            <el-badge is-dot
                      type="danger"
                      class="mr-15"></el-badge>更新中
          </span>
          <span v-else>
            <el-badge is-dot
                      type="danger"
                      class="mr-15"></el-badge>已结束
          </span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="核销率">
        <template slot-scope="scope">
          {{scope.row.verify_probability>0?scope.row.verify_probability+'%':''}}
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="预算来源">
        <template slot-scope="scope">
          {{scope.row.budget_source | budgetName}}
        </template>
      </el-table-column>
      <el-table-column label="操作"
                       width="320">
        <template slot-scope="scope">
          <el-button type="text"
                     class="mr-15"
                     @click="expend(scope)">实时消耗数据</el-button>
          <FileExport :params="{...scope.row.charge_export}"
                      v-if="scope.row.status === 'NORMAL'"
                      class="mr-15"
                      type="text">下载消耗明细</FileExport>
          <el-dropdown trigger="click"
                       v-permission="['ADMIN']"
                       @command="handleCommand($event,scope)">
            <span class="el-dropdown-link">
              <el-button type="text">更多操作</el-button>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="adjustBudet">调整预算</el-dropdown-item>
              <el-dropdown-item command="edit"
                                v-permission="['ADMIN']">编辑</el-dropdown-item>
              <el-dropdown-item command="del"
                                v-permission="['ADMIN']">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </table-page>
    <SetActivity ref="setActivity"
                 @on-success="setActivitySuccess" />
    <AdjustBudet ref="adjustBudet"
                 @on-success="adjustBudetSuccess" />
    <ChangeBudet @on-success="$refs.table.fetch()" />
    <Expend ref="expend"
            @on-success="$refs.table.fetch()" />
    <AdjustLog ref="adjustLog" />
    <ChangeLog ref="changeLog" />
    <AdjustDeduct ref="adjustDeduct"
                  @on-success="queryBudget()" />

    <AdjustSumBudet ref="adjustSumBudet"
                    @on-success="queryBudget()" />
  </div>
</template>

<script>
import CurrentSum from './components/CurrentSum'
import TablePage from '@/components/TablePage'
import SetActivity from './components/SetActivity'
import AdjustBudet from './components/AdjustBudet'
import AdjustDeduct from './components/AdjustDeduct'
import AdjustSumBudet from './components/AdjustSumBudet'
import ChangeBudet from './components/ChangeBudet'
import AdjustLog from './components/AdjustLog'
import ChangeLog from './components/ChangeLog'
import Expend from './components/Expend'
import FileExport from '@/components/FileExport'
import { querySearh, queryDelActivity, queryBudget } from '@/api/market'
import { toThousandFilter } from '@/utils'
export default {
  name: 'Query',
  data () {
    return {
      verify_probability: 0,
      sumLoading: false,
      isCurrent: false,
      budget: null,
      searchParams: null,
      exportParam: null,
      querySearh,
      search: [{
        type: 'area',
        level: 3
      }, {
        type: 'input',
        placeholder: '请输入活动名称',
        name: '活动名称',
        key: 'name',
        value: ''
      }, {
        type: 'input',
        placeholder: '请输入负责人名称',
        name: '负责人',
        key: 'creator',
        value: ''
      }, {
        type: 'input',
        placeholder: '请输入批次号',
        name: '批次号',
        key: 'stock_id',
        value: ''
      }, {
        type: 'select',
        key: 'is_postal',
        value: '',
        name: '是否邮储卡',
        list: [{
          value: true,
          label: '是'
        },
        {
          value: false,
          label: '否'
        }]
      }, {
        type: 'select',
        key: 'type',
        value: '',
        name: '活动类型',
        list: [{
          value: 'RANDOM',
          label: '随机立减'
        },
        {
          value: 'RATION',
          label: '定额立减'
        },
        {
          value: 'PUBLIC',
          label: '公开派券'
        }, {
          value: 'MARKETER',
          label: '员工派券'
        }, {
          value: 'DISCOUNT',
          label: '折扣立减'
        }]
      }, {
        type: 'select',
        key: 'budget_source',
        value: '',
        name: '预算来源',
        list: [{
          value: 'ENERGIZE',
          label: '赋能下单'
        },
        {
          value: 'BALANCE',
          label: '余额下单'
        },
        {
          value: 'E_AND_B',
          label: '赋能+余额'
        }]
      },
      {
        type: 'input',
        placeholder: '请输入赋能单号',
        name: '赋能单号',
        key: 'energize_no',
        value: ''
      },
      {},
      {
        type: 'date',
        placeholder: '开始时间',
        name: '开始时间',
        key: 'started_at',
        value: ''
      },
      {
        type: 'date',
        placeholder: '结束时间',
        name: '结束时间',
        key: 'ended_at',
        value: ''
      }],
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'SUBMITED',
        label: '未开始'
      }, {
        value: 'PENDING',
        label: '进行中'
      }, {
        value: 'WAITING',
        label: '更新消耗数据中'
      }, {
        value: 'NORMAL',
        label: '已结束'
      }],
      typeList: [{
        value: false,
        label: '查看辖内所有活动'
      }, {
        value: true,
        label: '仅查看当前层级活动'
      }]
    }
  },
  components: {
    TablePage,
    FileExport,
    SetActivity,
    AdjustBudet,
    ChangeBudet,
    AdjustSumBudet,
    Expend,
    AdjustDeduct,
    AdjustLog,
    ChangeLog,
    CurrentSum
  },
  computed: {
    role () {
      return this.$store.state.user.role
    },
    areaTxt () {
      if (this.searchParams) {
        return this.searchParams.province ? this.searchParams.province + this.searchParams.city + this.searchParams.district : '全部'
      } else {
        return '全部'
      }
    }
  },
  filters: {
    toThousandFilter,
    typeName (param) {
      switch (param) {
        case 'RANDOM':
          return '随机立减'
        case 'RATION':
          return '定额立减'
        case 'PUBLIC':
          return '公开派券'
        case 'MARKETER':
          return '员工派券'
        case 'DISCOUNT':
          return '折扣立减'
        default:
          break
      }
    },
    budgetName (param) {
      switch (param) {
        case 'ENERGIZE':
          return '赋能下单'
        case 'BALANCE':
          return '余额下单'
        case 'E_AND_B':
          return '赋能+余额'
        default:
          break
      }
    }
  },
  methods: {
    currentSum () {
      this.$refs.currentSum.showDialog(this.searchParams)
    },
    setActivitySuccess () {
      this.$refs.table.fetch()
      this.queryBudget()
    },
    onSearch (res) {
      this.searchParams = JSON.parse(JSON.stringify(res))
      this.queryBudget()
    },
    adjustBudetSuccess () {
      this.$refs.table.fetch()
      this.queryBudget()
    },
    typeChange (res) {
      this.isCurrent = res
      this.$refs.table.fetch()
    },
    fetchSuccess (res, searchParam) {
      this.verify_probability = res.verify_probability
      if (res.activity_export) {
        this.exportParam = res.activity_export
      } else {
        this.exportParam = null
      }
    },
    async queryBudget (params) {
      this.sumLoading = true
      const res = await queryBudget(this.searchParams)
      this.sumLoading = false
      if (res.meta.code === 0) {
        this.budget = res.data
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    expend (scope) {
      this.$refs.expend.showDialog(scope)
    },
    delActivity (id) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await queryDelActivity({
          id: id
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        } else {
          this.$message.error(res.meta.msg)
        }
      }).catch(() => {

      })
    },
    searchFun (res) {
      return res
    },
    handleCommand (command, scope) {
      switch (command) {
        case 'white':
          this.$router.push({ name: 'PublicWhites', query: { id: scope.row.id } })
          break
        case 'edit':
          this.$refs.setActivity.showDialog(scope)
          break
        case 'adjustBudet':
          this.$refs.adjustBudet.showDialog(scope.row)
          break
        case 'del':
          this.delActivity(scope.row.id)
          break
        default:
          break
      }
    }
  },
  mounted () {
    const userInfo = this.$store.state.user
    const user = userInfo.userInfo
    switch (userInfo.role) {
      case 'MANAGE_PROVINCE':
        this.searchParams = {
          province: user.province,
          city: '',
          district: '',
          net: ''
        }

        break
      case 'MANAGE_CITY':
        this.searchParams = {
          province: user.province,
          city: user.city,
          district: '',
          net: ''
        }
        break
      case 'MANAGE_DISTRICT':
        this.searchParams = {
          province: user.province,
          city: user.city,
          district: user.district,
          net: ''
        }
        break
      case 'MANAGE_NET':
        this.searchParams = {
          province: user.province,
          city: user.city,
          district: user.district,
          net: ''
        }
        break
      default:
        this.searchParams = {
          province: '',
          city: '',
          district: '',
          net: ''
        }
        break
    }
    this.$nextTick(() => {
      this.queryBudget()
    })
  }
}
</script>

<style lang="scss" scoped>
.sum-wrap {
  margin-top: 15px;
  .sum-item {
    color: #6a6a6a;
    text-align: center;

    .title {
      font-size: 12px;
    }
    .sum {
      margin-top: 10px;

      font-size: 36px;
      font-weight: bold;
    }
  }
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 100%;
}
.description {
  padding: 8px 16px;
  background-color: #f0f9eb;
  color: #67c23a;
  font-size: 13px;
  .title {
    font-weight: 700;
    line-height: 18px;
  }
  .desc-content {
    margin-top: 5px;
    a {
      margin-left: 15px;
      font-weight: 700;
      cursor: pointer;
    }
  }
}
</style>
