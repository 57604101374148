<template>
  <el-dialog title="活动设置"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible"
             width="40%">
    <el-form :model="form"
             :rules="rules"
             ref="form"
             label-position="top">
      <el-form-item label="活动区域"
                    prop="province">
        <Area :level="3"
              v-model="areaAddress"
              @change="areaChange" />
      </el-form-item>
      <el-form-item label="活动名称"
                    prop="name">
        <el-input type="text"
                  placeholder="请输入活动名称"
                  v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="活动类型"
                    prop="type">
        <el-select v-model="form.type"
                   class="width300"
                   placeholder="请选择">
          <el-option v-for="item in typeList"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="批次号"
                    prop="stock_ids">
        <div class="flex"
             v-if="!form.id">
          <el-input type="text"
                    class="mr-15 width300"
                    placeholder="请输入批次号"
                    v-model="stockId"></el-input>
          <el-button type="primary"
                     @click="addVoucher">添加</el-button>
        </div>
        <div>
          <el-tag type="success"
                  class="mr-15"
                  v-for="(item,index) in form.stock_ids"
                  :closable="form.id?false:true"
                  @close="delVoucher(index)"
                  :key="index">{{item}}</el-tag>
        </div>
      </el-form-item>
      <el-form-item label="预算来源"
                    prop="budget_source">
        <el-select v-model="form.budget_source"
                   :disabled="form.id?true:false"
                   class="width300"
                   placeholder="请选择">
          <el-option v-for="item in sourceList"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="赋能单号"
                    key="energize_nos"
                    v-if="form.budget_source === 'ENERGIZE' || form.budget_source==='E_AND_B'"
                    prop="energize_nos">
        <div class="flex"
             v-if="!form.id">
          <el-input type="text"
                    class="mr-15 width300"
                    placeholder="请输入赋能单号"
                    v-model="energizeNo"></el-input>
          <el-button type="primary"
                     @click="addEnergizeNo">添加</el-button>
        </div>
        <div>
          <el-tag type="success"
                  :closable="form.id?false:true"
                  @close="delEnergizeNo(index)"
                  v-for="(item,index) in form.energize_nos"
                  :key="index">{{item}}</el-tag>
        </div>
      </el-form-item>
      <el-form-item label="赋能下单金额"
                    v-if="form.budget_source==='E_AND_B'"
                    key="energize_money"
                    prop="energize_money">
        <el-input type="text"
                  :disabled="form.id?true:false"
                  placeholder="请输入金额，单位元"
                  v-model="form.energize_money"></el-input>
      </el-form-item>
      <el-form-item label="仅邮储卡可享受"
                    prop="is_postal">
        <el-radio-group v-model="form.is_postal">
          <el-radio :label="false">否</el-radio>
          <el-radio :label="true">是</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea"
                  :rows="4"
                  placeholder="请输入备注"
                  v-model="form.desc"></el-input>
      </el-form-item>
      <el-form-item label="负责人"
                    prop="creator">
        <el-input type="text"
                  placeholder="请输入负责人名称"
                  v-model="form.creator"></el-input>
      </el-form-item>

      <el-form-item label="文件上传"
                    key="file_data"
                    v-if="form.budget_source === 'BALANCE'">
        <div v-if="form.file_data">
          <a :href="form.file_data.url"
             v-if="form.file_data">{{form.file_data.source_name}}</a>
          <el-button type="text"
                     size="mini"
                     class="ml-15"
                     @click="delFormFile">删除</el-button>
        </div>
        <input type="file"
               @change="handleFile"
               v-else />

      </el-form-item>
    </el-form>

    <span slot="footer"
          class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"
                 v-if="form.id"
                 :loading="loading"
                 @click="querySetActivity('form')">修改</el-button>
      <el-button type="primary"
                 :loading="loading"
                 v-else
                 @click="queryAddActivity('form')">立即创建</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Area from '@/components/Area'
import { querySetActivity, queryAddActivity, queryAddVoucher } from '@/api/market'
export default {
  data () {
    var validatPrices = (rule, value, callback) => {
      if (rule.required === false && (value === '' || value === null || value === undefined)) {
        callback()
      }
      const isPrice = /(^[-]?[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/
      if (isPrice.test(value)) {
        callback()
      } else {
        callback(new Error(rule.message || '请输入正确的金额，例：10, 10.00'))
      }
    }
    return {
      loading: false,
      typeList: [{
        value: 'RANDOM',
        label: '随机立减'
      }, {
        value: 'RATION',
        label: '定额立减'
      }, {
        value: 'PUBLIC',
        label: '公开派券'
      }, {
        value: 'MARKETER',
        label: '员工派券'
      }, {
        value: 'DISCOUNT',
        label: '折扣立减'
      }],
      sourceList: [{
        value: 'ENERGIZE',
        label: '赋能下单'
      }, {
        value: 'BALANCE',
        label: '使用余额'
      }, {
        value: 'E_AND_B',
        label: '赋能加余额'
      }],
      file: null,
      value: '',
      areaAddress: {},
      dialogVisible: false,
      stockId: '',
      energizeNo: '',
      form: {
        province: '',
        city: '',
        district: '',
        net: '',
        name: '',
        type: '',
        is_postal: false,
        budget_source: '',
        stock_ids: [],
        energize_nos: [],
        energize_money: 0,
        code: '',
        quantity: '',
        creator: ''
      },
      rules: {
        province: [
          { required: true, message: '至少选择到省', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择活动类型', trigger: 'change' }
        ],
        budget_source: [
          { required: true, message: '请选择预算来源', trigger: 'change' }
        ],
        stock_ids: [
          { type: 'array', required: true, message: '请添加批次号', trigger: 'change' }
        ],
        energize_money: [
          { required: true, message: '请填写赋能下单金额', trigger: 'blur' },
          { validator: validatPrices, trigger: 'blur' }
        ],
        is_postal: [
          { required: true, message: '请选择是否邮储卡可享受', trigger: 'change' }
        ],
        creator: [
          { required: true, message: '请填写负责人名称', trigger: 'blur' }
        ],
        energize_nos: [
          { type: 'array', required: true, message: '请添加赋能单号', trigger: 'change' }
        ]
      }
    }
  },
  components: {
    Area
  },
  created () {

  },
  mounted () {
  },
  methods: {
    delFormFile () {
      this.form.file_id = 0
      this.form.file_data = null
      this.file = null
    },
    handleFile (e) {
      const $target = e.target || e.srcElement
      const file = $target.files[0]
      if (!file) {
        return
      }
      this.file = file
    },
    delEnergizeNo (index) {
      this.form.energize_nos.splice(index, 1)
    },
    delVoucher (index) {
      this.form.stock_ids.splice(index, 1)
    },
    areaChange (res) {
      this.form.province = res.province
      this.form.city = res.city
      this.form.district = res.district
      this.form.net = res.net
    },
    async addVoucher () {
      if (this.stockId === '') { this.$message.error('请输入批次号') } else {
        const flag = this.form.stock_ids.find((item) => { return item === this.stockId })
        if (flag) {
          this.$message.error('请勿添加相同批次号')
          return
        }
      }
      const res = await queryAddVoucher({
        stock_id: this.stockId
      })
      if (res.meta.code === 0) {
        this.form.stock_ids.push(this.stockId)
        this.stockId = ''
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    addEnergizeNo () {
      if (this.energizeNo !== '') {
        const flag = this.form.energize_nos.find((item) => { return this.energizeNo === item })
        if (flag) {
          this.$message.error('请勿重复添加')
        } else {
          this.form.energize_nos.push(this.energizeNo)
          this.energizeNo = ''
        }
      } else {
        this.$message.error('请输入赋能单号')
      }
    },
    async queryAddActivity (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.form.stock_ids.length === 0) {
            this.$message.error('请添加代金券')
            return
          }
          if (this.form.budget_source === 'ENERGIZE' || this.form.budget_source === 'E_AND_B') {
            if (this.form.energize_nos.length === 0) {
              this.$message.error('请添加赋能单号')
              return
            }
          }
          if (this.form.budget_source === 'BALANCE' && !this.file) {
            return this.$message.error('请上传文件')
          }
          const formData = JSON.parse(JSON.stringify(this.form))
          formData.energize_money *= 100
          this.loading = true
          const formData2 = new FormData()
          Object.keys(formData).forEach(function (key) {
            if (Array.isArray(formData[key])) {
              formData[key].map((item) => {
                formData2.append(key + '[]', item)
              })
            } else {
              formData2.append(key, formData[key])
            }
          })
          formData2.append('file', this.file)
          const res = await queryAddActivity(formData2)
          this.loading = false
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: '创建成功',
              type: 'success'
            })
            this.$emit('on-success')
            this.dialogVisible = false
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          return false
        }
      })
    },
    querySetActivity (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.loading = true
          const formData = JSON.parse(JSON.stringify(this.form))
          formData.energize_money *= 100
          this.loading = true
          const formData2 = new FormData()
          Object.keys(formData).forEach(function (key) {
            if (Array.isArray(formData[key])) {
              formData[key].map((item) => {
                formData2.append(key + '[]', item)
              })
            } else {
              formData2.append(key, formData[key])
            }
          })
          if (this.form.budget_source === 'BALANCE') {
            if (this.file) {
              formData2.append('file', this.file)
            } else {
              if (!formData.file_data) {
                this.loading = false
                this.$message.error('请上传文件')
                return
              } else {
                formData2.append('file_id', formData.file_data.id)
              }
            }
          }

          const res = await querySetActivity(formData2)
          this.loading = false
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success'
            })
            this.$emit('on-success')
            this.dialogVisible = false
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          return false
        }
      })
    },
    showDialog (scope) {
      this.dialogVisible = true
      this.stockId = ''
      this.energizeNo = ''
      this.file = null
      if (scope) {
        const formData = JSON.parse(JSON.stringify(scope.row))
        formData.energize_money = parseFloat(formData.energize_money)
        formData.energize_money /= 100
        this.areaAddress = {
          province: formData.province,
          city: formData.city,
          district: formData.district,
          net: formData.net
        }
        this.form = formData
      } else {
        this.areaAddress = {
          province: '',
          city: '',
          district: '',
          net: ''
        }
        this.form = {
          province: '',
          city: '',
          district: '',
          net: '',
          name: '',
          type: '',
          is_postal: false,
          budget_source: '',
          stock_ids: [],
          energize_nos: [],
          energize_money: 0,
          code: '',
          quantity: '',
          creator: ''
        }
      }

      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.file {
  position: relative;
  background: #409eff;
  color: #ffffff;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  font-weight: 500;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
}
.file input {
  position: absolute;
  font-size: 100px;
  right: 0;
  top: 0;
  opacity: 0;
}
</style>
