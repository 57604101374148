<template>
  <el-dialog title="实时消耗查询"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible"
             width="50%">
    <el-alert title="实时数据持续有变化，最终数据以活动结束后下载的明细为准。"
              type="error"
              class="mb-15"
              effect="dark"
              :closable="false">
    </el-alert>
    <div v-loading="loading"
         class="form-wrap">
      <el-form :model="form"
               label-width="120px"
               label-position="left">
        <template v-if="activity">
          <el-form-item label="活动名称">
            {{activity.name}}
          </el-form-item>
          <el-form-item label="选择批次号"
                        prop="code">
            <span v-for="(item,index) in activity.stock_ids"
                  @click="stockQuery(item,index)"
                  :class="['item','mr-15',isIndex === index?'active':'']"
                  :key="index">{{item}}</span>
          </el-form-item>
        </template>
        <template v-if="form && !error">
          <el-form-item label="批次名称">
            {{form.name}}
          </el-form-item>
          <el-form-item label="批次类型">
            {{form.type==='DISCOUNT_CUT'? '支付立减':''}}
            {{form.type==='NORMAL' ? '代金券':''}}
            {{form.type==='OTHER' ? '其他':''}}
          </el-form-item>

          <el-form-item label="批次状态">
            {{form.status==='unactivated'? '未激活':''}}
            {{form.status==='audit' ? '审核中':''}}
            {{form.status==='running' ? '运行中':''}}
            {{form.status==='stoped' ? '已停止':''}}
          </el-form-item>
          <el-form-item label="优惠门槛">
            {{form.ur_fnc_transaction_minimum/100}} 元
          </el-form-item>
          <el-form-item label="优惠面额">
            {{form.ur_fnc_coupon_amount/100===0?'随机立减无固定面额':form.ur_fnc_coupon_amount/100+' 元'}}
          </el-form-item>
          <el-form-item label="批次总预算">
            {{form.ur_max_amount/100}} 元
          </el-form-item>
          <el-form-item label="每日预算上限">
            {{form.ur_max_amount_by_day/100===0?'代金券无每日预算上限':form.ur_max_amount_by_day/100+' 元'}}
          </el-form-item>
          <el-form-item label="已发券数"
                        v-if="form.type === 'NORMAL'">
            {{form.distributed_coupons}} 张
          </el-form-item>

          <el-form-item label="已减价笔数">
            {{form.total_order}} 笔
          </el-form-item>
          <el-form-item label="已消耗总金额">
            {{form.consume_money/100}} 元
          </el-form-item>
          <el-form-item label="今日已消耗金额"
                        v-if="form.type!== 'NORMAL'">
            {{form.today_consume_money/100}} 元
          </el-form-item>
        </template>
        <template v-if="error">
          <el-alert title="仅支持查询2019-12-28后开始的代金券/支付立减活动消耗数据，查询此时间之前的批次号将出现数据不准确/查无批次号的情况。"
                    type="warning"
                    show-icon>
          </el-alert>
        </template>

      </el-form>

    </div>

    <span slot="footer"
          class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"
                 @click="dialogVisible = false">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { searchQuery } from '@/api/market'
export default {
  data () {
    return {
      value: '',
      areaAddress: {},
      dialogVisible: false,
      form: null,
      isIndex: 0,
      loading: false,
      activity: null,
      error: false
    }
  },
  components: {
  },
  created () {

  },
  mounted () {
  },
  methods: {
    async stockQuery (item, index) {
      this.isIndex = index
      this.loading = true
      const res = await searchQuery({
        stock_id: item
      })
      this.loading = false
      if (res.meta.code === 0) {
        this.error = false
        this.form = res.data.stock
      } else if (res.meta.code === 4004) {
        this.error = true
        this.msg = res.meta.msg
      } else {
        this.error = false
        this.$message.error(res.meta.msg)
      }
    },
    showDialog (scope) {
      this.dialogVisible = true
      this.activity = scope.row
      this.$nextTick(() => {
        if (scope.row.stock_ids[0]) {
          this.stockQuery(scope.row.stock_ids[0], 0)
        }
      })
    }
  }
}
</script>

<style scoped lang="css">
.item {
  cursor: pointer;
}
.active {
  background: #57ba73;
  padding: 5px 10px;
  border-radius: 8px;
  color: white;
  cursor: pointer;
}
.form-wrap >>> .el-form-item__label {
  font-weight: bold !important;
}
</style>
