<template>
  <div class="">

  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {

    }
  },
  components: {
  },
  methods: {

  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
